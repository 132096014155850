import React from "react"

/** components */
import { ColorPalette } from "../Theme"

/** props */
interface Props {
  backgroundColorOne: string
  backgroundColorTwo: string
  badgeTitle: string
  bottomMargin?: number
  buttonName: string
  buttonUrl?: string
  colorPalette: ColorPalette
  colSizeOne: number
  colSizeTwo: number
  fontTextColor: string
  image: string
  imageTitle: string
  subtitle: string
  title: string
  titleTopLeft: string
  imageSubtitle?: string
  imageTitleSubtitle?: string
  asoEffect: string
}

/** const */
const AppliancesProductSection: React.FC<Props> = (props) => {
  return (
    <section
      className={`container-fluid text-left p-5 pt-5 pb-5`}
      style={{
        background: "#EFEFEF",
        color: "#365c70",
      }}
    >
      <div className="container">
        <div
          className="contentHeroLeftTitle hidden-sm hidden-md hidden-lg hidden-xl m-0 p-0"
          style={{ fontSize: "24px", color: "#365c70" }}
        >
          Appliances
        </div>

        <hr
          className="hidden-sm hidden-md hidden-lg hidden-xl style-line"
          style={{
            borderColor: "rgba(0, 0, 0, 0.2)",
            marginTop: "10px",
            marginBottom: "10px",
            color: "",
          }}
        />


       <div class="container m-0 p-0 mt-3 mb-5 d-none d-sm-block">
          <div class="row">
            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/appliances/1.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>

            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/appliances/2.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>

            <div class="col-4 col-md-4">
              <img
                data-aos="Fade"
                src="/appliances/3.jpg"
                loading="lazy"
                decoding="async"
                alt=""
                width="100%"
              />
            </div>
          </div>
        </div>

       <div class="container m-0 p-0 d-block d-sm-none">
          <img
            data-aos="Fade"
            src="/appliances/3.jpg"
            loading="lazy"
            decoding="async"
            alt=""
            width="100%"
          />
        </div>


        <div className="contentHeroSpace hidden-xs"></div>

        <div className="row">
         <div
           className="contentHeroLeftTitle hidden-sm hidden-md hidden-lg hidden-xl m-0 p-0"
           style={{ fontSize: "18px", color: "#365c70" }}
         >
         </div>

         <div
            className="hidden-sm hidden-md hidden-lg hidden-xl mb-0 p-0"
            style={{ fontSize: "18px", color: "" }}
          >
            Norhart Supply carries a variety of modern appliances.  With a variety of colors and features, we have the appliances to fit any residence's needs.  We supply appliances necessary for kitchens and laundry rooms. Ranging in colors from white and black to brushed nickel, Norhart Supply has appliances that will tie together any kitchen.

            <p/>

              <strong>Dishwashers</strong>
              <br/>
              The dishwashers come with several special wash cycles.  These dishwashers have lower decibel ratings and are very quiet.  Norhart Supply has dishwashers in varying sizes to best utilize your kitchens space.  With multiple color options, you can easily match the other appliances necessary.
              <p/>
              <hr/>

              <strong>Washer & dryers</strong>
              <br/>
               We carry washers and dryers that cater to any laundry room.  Whether you need a small, stacked unit for an apartment unit, or a full-size, separate washer and dryer, Norhart Supply has what you need.  These size options allow for space to be optimized.  These appliances come in several colors.
              <p/>
              <hr/>

              <strong>Ranges</strong>
              <br/>
              Norhart Supply provides electric ranges for your kitchen needs.  In apartment units, or smaller kitchens, electric ranges keep your space cooler and are more convenient when cooking.  In the multifamily industry, these ranges are ideal.  The ranges we carry come in a variety of colors.
              <p/>
              <hr/>

              <strong>Microwaves</strong>
              <br/>
              The microwaves are designed to go with your other kitchen appliances.  Microwaves are a great convenience factor to have in any kitchen.  Microwaves are one of the most used kitchen appliances and are a great asset to any kitchen.  Norhart Supply has microwaves in colors to match your range, dishwasher and refrigerator.
              <p/>
              <hr/>

              <strong>Refrigerators</strong>
              <br/>
              Norhart Supply carries refrigerators to fit any kitchen space.  We carry refrigerators in a variety of sizes in order to best utilize the space of any kitchen.  We have refrigerators with bottom freezers, top freezers, as well as side by side refrigerators.  Along with all the other appliances we carry, the refrigerators also come in a variety of colors to match.

            <p/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AppliancesProductSection
